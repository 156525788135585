import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/auth/Login'
import Admin from '../views/Admin'

Vue.use(VueRouter)

const routes = [{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/admin',
		name: 'admin',
		component: Admin,
		children: [{
				path: '/dashboard',
				name: 'dashboard',
				component: () => import('../views/Dashboard'),
			},
			{
				path: '/order-list',
				name: 'order-list',
				component: () => import('../views/order/OrderList'),
			},
			{
				path: '/consumer-order-list',
				name: 'consumer-order-list',
				component: () => import('../views/order/ConsumerOrderList'),
			},
			{
				path: '/return-list',
				name: 'return-list',
				component: () => import('../views/return/ReturnList'),
			},
			{
				path: '/consumer-return-list',
				name: 'consumer-return-list',
				component: () => import('../views/return/ConsumerReturnList'),
			},
			{
				path: '/return-create',
				name: 'return-create',
				component: () => import('../views/return/ReturnCreate'),
			},
			{
				path: '/consumer-return-create',
				name: 'consumer-return-create',
				component: () => import('../views/return/ConsumerReturnCreate'),
			},
		]
	},

	{
		path: '/*',
		redirect: '/dashboard'
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})


export default router