import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import env from '../enviorments'
import header from '../header'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        token: null,
        user: null,
    },
    getters: {

    },
    mutations: {
        mutateUser(state, user) {
            state.user = user
        },
        mutateToken(state, token) {
            state.token = token
        },
    },
    actions: {
        async userLogin({commit}, user) {
            const result = (await axios.post(env.apiUrl + 'transporter-user-login', {...user})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            window.localStorage.setItem('token', JSON.stringify(result.data.token))
            result.data.password = null,
            window.localStorage.setItem('user', JSON.stringify(result.data))
            commit('mutateToken', result.token)
            commit('mutateUser', result.data)
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async dashboardAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'dashboard', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async orderListAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'order-list', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async consumerOrderListAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'consumer-order-list', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async statusChangeAction({commit}, order) {
            const result = (await axios.post(env.apiUrl + 'status-change', {...order}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async consumerStatusChangeAction({commit}, order) {
            const result = (await axios.post(env.apiUrl + 'consumer-status-change', {...order}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async distributorListAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'distributor-list', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async returnListAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'return-list', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async returnItemAction({commit}, distributor) {
            const result = (await axios.post(env.apiUrl + 'return-item', {...distributor}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async returnStoreAction({commit}, data) {
            const result = (await axios.post(env.apiUrl + 'return-item-store', {...data}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async consumerReturnListAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'consumer-return-list', {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async consumerReturnItemAction({commit}, distributor) {
            const result = (await axios.post(env.apiUrl + 'consumer-return-item', {...distributor}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async consumerReturnStoreAction({commit}, data) {
            const result = (await axios.post(env.apiUrl + 'consumer-return-item-store', {...data}, {headers: header.getToken()})).data
            if (result.status == 'fail') return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
    },
    modules: {
    }
})
